import axios from 'axios'

const APIMixin = {
    data: {
        api_status: '',
        api_data: '',
        api_error: '',
        api_url_prefix: '/api/v1/',
        api_url: '',
        api_post_data: ''
    },
    created() {
    },
    methods: {
        
        _getAPIUrl( url ) {
            return this.api_url_prefix + url + '/'
        },

        _addAPIPostData( data ){
            var frm = new FormData()
            for(var key in data ){
                var value = data[key]
                frm.append(key, value)
            }
            return frm
        },

        _beforeAPIRequest() {
            this.$store.commit( 'setLoading', true )
        },

        _handleAPIResponse( response ) {
            this.api_status = response.status
            this.api_url = ''
            this.$store.commit( 'setLoading', false )
        },

        async apiCreate( url, formData ) {
            this.$store.commit( 'setLoading', true )
            var data = {
                url: this._getAPIUrl( url ),
                status: 0,
                post_data: this._addAPIPostData( formData ),
                data: [],
                errors: []
            }

            try{

                let r = await axios({
                    url: data['url'],
                    method: 'post',
                    data: formData
                })

                data['status'] = r.status
                data['data'] = r.data

            } catch ( err ) {
                console.log("APIv3 error (use this to better debug)", err )
                if(err.response) {
                    for(const property in err.response.data){
                        data['errors'].push(`${property}: ${err.response.data[property]}`)
                    }
                } else if(error.message){
                    data['errors'].push('Something went wrong, please try again.')
                } else {
                    data['errors'] = error
                }
            } finally {
                this.$store.commit( 'setLoading', false )
                return data;
            }

        },

        async apiReadAll( url ) {
            this.$store.commit( 'setLoading', true )
            
            var data = {
                url: this._getAPIUrl( url ),
                status: 0,
                data: []
            }

            try{
                let res = await axios({
                            method: 'get',
                            url: data['url'],
                        })
                data['status'] = res.status
                if(res.status == 200 ){
                    data['data'] = res.data
                }

            } catch ( error ) {
                console.log( "APIMixing ReadAll error: ", error )
                data['error'] = error
            }
            
            this.$store.commit( 'setLoading', false )
            return data
        },

        async apiReadSingle( endpoint ) {
            var res = []
            try{
                this.api_url = endpoint

                let res = await axios({
                                    method: 'get',
                                    url: this._getAPIUrl(),
                                })

                this._handleAPIResponse( res )

                if( this.api_status == 200 ){
                    this.api_data = res.data;
                }

            } catch ( error ) {
                console.log( "APIMixing ReadSingle error: ", error )
                this.api_error
            } finally {
                return res
            }
        },

        async apiPut( endpoint, putData ) {
            var res = []
            try{
                this.api_url = endpoint
                this._addAPIPostData( putData )

                let res = await axios({
                                    method: 'put',
                                    url: this._getAPIUrl(),
                                    data: this.api_post_data
                                })

                this._handleAPIResponse( res )

                if(this.api_status == 200 || this.api_status == 204 ){
                    this.api_data = res.data;
                }

            } catch ( error ) {
                console.log( "APIMixing  error: ", error )
                this.api_error = error
            } finally {
                return res
            }
        },

        /** Delete an entity
         * 
         * @param {*} url 
         * @returns 
         */
        async apiDelete( url ) {
            this.$store.commit( 'setLoading', true )
            var data = {
                url: this._getAPIUrl( url ),
                status: 0,
                data: [],
                errors: []
            }

            var res = []
            try{

                let r = await axios({
                                    method: 'delete',
                                    url: data['url'],
                                })
                console.log("Delete Response:", r)
                data['status'] = r.status
                data['data'] = r.data

            } catch ( error ) {
                console.log("APIMixinv3 delete error: ", err )
                if(err.response) {
                    for(const property in err.response.data){
                        data['errors'].push(`${property}: ${err.response.data[property]}`)
                    }
                } else if(error.message){
                    data['errors'].push('Something went wrong, please try again.')
                } else {
                    data['errors'] = error
                }
            } finally {
                this.$store.commit( 'setLoading', false )
                return data
            }
        },

    }
}
export default APIMixin