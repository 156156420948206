<template>
  <div class="col-md-6 mx-auto">
      <div class="card">
          <div class="card-body p-5">
                <h1 class="display-6">Sign Up</h1>
                <div class="col-md-6 dblock mx-auto">
                <form @submit.prevent="submitForm">
                    <FormGroup class="my-3">
                        <InputEmail v-model:modelvalue="email" label="Email" id="inputSignUpEmail" :required="false" />
                    </FormGroup>
                    <FormGroup class="my-3">
                        <InputPassword v-model:modelvalue="password1" label="Password" id="inputSignUpPassword1" :required="false" />
                    </FormGroup>
                    <FormGroup class="my-3">
                        <InputPassword v-model:modelvalue="password2" label="Repeat Password" id="inputSignUpPassword2" :required="false" />
                    </FormGroup>
                    <FormGroup class="my-3">
                        <button class="btn btn-danger">Sign Up</button>
                    </FormGroup>
                </form>
                </div>
  </div>
  </div>
  </div>
</template>

<script>

    import axios from 'axios'
    import FormGroup from '@/components/layout/forms/layout/FormGroup'
    import InputEmail from '@/components/layout/forms/fields/InputEmail'
    import InputPassword from '@/components/layout/forms/fields/InputPassword'

export default {
  name: 'SignUp',
  components: {
      FormGroup,
      InputEmail,
      InputPassword
  },
  data(){
      return {
          email: '',
          password1: '',
          password2: '',
          errors: []
      }
  },
  methods: {
     async submitForm() {
          

          this.errors = []

          if( this.email === '' ){
              this.errors.push( 'the username is missing.' )
          }
          if( this.password1 === '' ){
              this.errors.push( 'the password is too short.' )
          }
          if( this.password1 !== this.password2 ){
              this.errors.push( 'Passwords dont match.' )
          }

          if( !this.errors.length){

          this.$store.commit('setLoading', true)

              const formData = {
                  username: this.email,
                  email: this.email,
                  password: this.password1
              }

        
                axios
                    .post('/api/v1/users/', formData)
                    .then(r => {
                        /*
                        toast({
                            message: 'Account was created, please log in.',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right'
                        })
                        */

                        this.$router.push('/login')
                    })
                    .catch(err => {
                        if(err.response) {
                            for(const property in err.response.data){
                                this.errors.push(`${property}: ${err.response.data[property]}`)
                            }
                        } else if(error.message){
                            this.errors.push('Something went wrong, please try again.')
                        }
                    } )

                

              

              
              

              
          }

          this.$store.commit('setLoading', false)

      }
  }
}
</script>