<template>
<div class="col">
<div :class="stacked ? '' : 'row' ">
<label :class="stacked ? 'lead pl-1' : 'lead col-md-4 pl-1'" v-if="show_label" :for="id">{{ label }}</label>
<div :class="stacked ? 'col': 'col-md-8'">
    <input 
        type="password" 
        class="form-control"
        :value="modelvalue"
        @input="$emit('update:modelvalue', $event.target.value)"
        :id="id"
        :name="id"
        :required="required"
        :placeholder="placeholder"
        :maxlength="max"
    />
</div>
</div>

</div>

</template>
<script>
/**
 *  Usage: 
 * 
 *      Props layout control:
 * 
 *          show_label: true    Shows the label or not;
 *          stacked: true       Show label on top of input
 *          
 *      Props Input control:
 * 
 *          value:              v-model value
 *          id:                 id name of the field
 *          placeholder:        placeholder of the input field
 *          required:           is the field required or not?
 *          max:                Max char entry
 *          
 */

export default {
    name: 'InputPassword',
    props: {
        
        label: String,
        show_label: {
            type: Boolean,
            default: true
        },
        stacked: {
            type: Boolean,
            default: true
        },

        modelvalue: String,
        id: String,
        placeholder: String,
        required: {
            type: Boolean,
            default: false
        },
        max: {
            type: Number,
            default: 255
        }
    }
}
</script>
