import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'

//import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue' //<= v2 Vue.js v2
//.use(BootstrapVue, BootstrapVueIcons) <= v2 Vue.js v2
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

//import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'popper.js/dist/popper.js'
import 'bootstrap/dist/js/bootstrap.js'


//axios.defaults.baseURL = 'http://127.0.0.1:8000'
axios.defaults.baseURL = 'https://devapi.roadmapper.nl'
//axios.defaults.baseURL = 'https://api.roadmapper.nl'

createApp(App).use(store).use(router, axios).mount('#app')
