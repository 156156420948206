import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'


import Login from '../views/registration/Login.vue'
import SignUp from '../views/registration/SignUp.vue'
import Dashboards from '../views/dashboards/Dashboards.vue'
import Dashboard from '../views/dashboards/Dashboard.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/helicopter',
    name: 'Dashboards',
    component: Dashboards,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if( to.matched.some(record => record.meta.requireLogin ) && ! store.state.isAuthenticated ) {
    next('/login')
  } else {
    next()
  }
})

export default router
