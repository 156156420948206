<template>
    <div :class="form_group_class">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'FormGroup',
    props: {
        row: {
            type: Boolean,
            default: true
        },
        class: {
            type: String,
            default: ''
        }
    }, 
    data(){
        return {
            form_group_class: ''
        }
    },
    mounted() {
        this.form_group_class = 'form-group '
        this.form_group_class += this.row ? 'row ' : ''
        this.form_group_class += this.class
    }
}
</script>