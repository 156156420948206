<template>
  <div>

    <Navbar title="Roadmapper" />

    <div class="container-fluid">
      <router-view/>
    </div>

  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>
  import axios from 'axios'
  
  import Navbar from '@/components/layout/Navbar'

  export default {
    name: 'App',
    components: {
        Navbar
    },
    beforeCreate(){
      this.$store.commit('initializeStore')

      axios.defaults.headers.common['Authorization'] = this.$store.state.token ? 
                                            "Token " + this.$store.state.token : 
                                                                            "" ;
      console.log(this.$store.state.token);
      /*if(this.$store.state.token){
        axios.defaults.headers.common['Authorization'] = "Token " + this.$store.state.token
      } else {
        axios.defaults.headers.common['Authorization'] = "" 
      }*/
    }
  }
</script>
