<template>
<header>
    <nav class="navbar navbar-dark bg-success">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">{{ title }}</a>

            <template v-if="$store.state.isAuthenticated">
            <ul class="nav col-12 col-lg-auto my-1 justify-content-right my-md-0 text-small">
                <li>
                    <a href="#" class="nav-link text-white">
                    <i class="fas fa-home d-block mx-auto mb-1"></i>
                    Home
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link text-white">
                    <i class="fas fa-helicopter d-block mx-auto mb-1"></i>
                    Dashboard
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link text-white">
                    <i class="fas fa-truck d-block mx-auto mb-1"></i>
                    Orders
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link text-white">
                    <i class="fas fa-truck d-block mx-auto mb-1"></i>
                    Invoices
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link text-white">
                    <i class="fas fa-users d-block mx-auto mb-1"></i>
                    Customers
                    </a>
                </li>
            </ul>
            </template>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav me-auto mb-2 mb-md-0">
                    <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" href="#">Link</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div class="px-3 py-2 border-bottom mb-3 bg-light">
        <div class="container d-flex flex-wrap justify-content-center">
        <form class="col-12 col-lg-auto mb-2 mb-lg-0 me-lg-auto">
            <input type="search" class="form-control" placeholder="Search..." aria-label="Search">
        </form>

        <div class="text-end">
            <template v-if="!$store.state.isAuthenticated">
                <div class="btn-group">
                    <router-link to="/sign-up" class="btn btn-danger mx-1"><strong>Sign Up</strong></router-link>
                    <router-link to="/login" class="btn btn-danger mx-1"><strong>Login</strong></router-link>
                </div>
            </template>
            <template v-else>
                <LogoutButton />
            </template>
        </div>
        </div>
    </div>
</header>
</template>
<script>

import LogoutButton from '@/components/widgets/registration/LogoutButton'

export default {
    name: 'Navbar',
    components: {
        LogoutButton
    },
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>