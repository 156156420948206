<template>

  <div class="col-md-6 d-block mx-auto">
      <div class="card">
          <div class="card-body p-5">
            <div class="col-md-6 d-block mx-auto">

                <h1 class="display-6">Login</h1>
                 <form @submit.prevent="submitForm">
                     <FormGroup :row="true" class="my-3" >
                        <InputEmail v-model:modelvalue="email" label="Email" id="inputLogInEmail" :required="false" />
                     </FormGroup>
                     <FormGroup :row="true" class="my-3" >
                        <InputPassword v-model:modelvalue="password" label="Password" id="inputLogInPassword" :required="false" />
                     </FormGroup>

                     <FormGroup :row="true" class="my-3" >
                        <button type="submit" class="btn btn-danger">Login</button>
                     </FormGroup>
                </form>
  </div>
  </div>
  </div>
  </div>
</template>

<script>

    import axios from 'axios'
    import FormGroup from '@/components/layout/forms/layout/FormGroup'
    import InputEmail from '@/components/layout/forms/fields/InputEmail'
    import InputPassword from '@/components/layout/forms/fields/InputPassword'

export default {
  name: 'Login',
  components: {
      InputEmail, 
      InputPassword,
      FormGroup
  },
  data(){
      return {
          email: '',
          password: '',
          errors: []
      }
  },
  methods: {
      async submitForm() {
          this.$store.commit('setLoading', true)

          axios.defaults.headers.common['Authorization'] = ''
          localStorage.removeItem('token')

           const formData = {
                  username: this.email,
                  password: this.password
           }

        
        axios
            .post('/api/v1/token/login/', formData)
            .then(r => {
                const token = r.data.auth_token
                this.$store.commit('setToken', token)
                axios.defaults.headers.common['Authorization'] = 'Token ' + token

                localStorage.setItem('token', token)

                /**
                 *  Get user administrations here. 
                 */
                
                this.$router.push('/helicopter')
            
            })
            .catch(err => {
                if(err.response) {
                    for(const property in err.response.data){
                        this.errors.push(`${property}: ${err.response.data[property]}`)
                    }
                } else if(error.message){
                    this.errors.push('Something went wrong, please try again.')
                }
            } )

        this.$store.commit('setLoading', false)
              
        }
      }
  }

</script>