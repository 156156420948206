import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: false,
    isAuthenticated: false,
    token: '',
    administrations: {},
    current_administration: {
      id: '',
      name: '',
      type: ''
    }
  },
  mutations: {
    initializeStore(state) {
      if(localStorage.getItem('token') ) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    setLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state ){
      state.token = ''
      state.isAuthenticated = false
      state.administrations = {}
      state.current_administration = {}
    },
    setAdministrations(state, administrations){
      state.administrations = administrations
    },
    setCurrentAdministration(state, administration){
      state.current_administration = administration
    }

  },
  actions: {
  },
  modules: {
  }
})
