<template>
    <button @click="logout()" :class="button_class">{{ title }}</button>
</template>
<script>

import axios from 'axios'
import APIMixin from '@/mixins/APIMixin'

export default {
    name: 'LogoutButton',
    mixins: [APIMixin],
    props: {
        title: {
            type: String,
            default: 'Log out'
        },
        class: {
            type: String,
            default: 'btn-danger'
        },
        next: {
            type: String,
            default: '/login'
        }
    },
    data() {
        return {
            button_basic: 'btn ',
            button_class: ''
        }
    },
    mounted() {
        this._fixButtonClass()
    },
    methods: {
        async logout() {
            let data = await this.apiCreate('token/logout')
            axios.defaults.headers.common['Authorization'] = ''
            localStorage.removeItem('token')
            this.$store.commit('removeToken')
            
            this.$router.push( this.next )
            
        },

        _fixButtonClass(){
            this.button_class = this.button_basic + this.class
        }
    }
}
</script>